import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';

import AnimatedRoutes from './components/AnimatedRoutes';
import { Crisp } from "crisp-sdk-web";
import React from "react"; 

class App extends React.Component {

  constructor() {
    super();
  }

  render() {
    return (
      <>
        <Header />
        <AnimatedRoutes />
        <Footer/>
      </>
      
    );
  }
  
  componentDidMount() {
    Crisp.configure("aa0731b0-f521-4309-85df-ddc21fa041bd");
  }
}

export default App;
