import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "price_header": "Clear pricing, no hidden costs.",
      "integration_header": "Seamless integration into your business",
      // header - menus
      "Home": "Home",
      "Integrations": "Integrations",
      "Demo": "Demo",
      "Price": "Price",
      "About": "About",
      "Contact": "Contact",
      "Sign-Up": "Try for Free",
      // home page
      "h-s1-h1": `TastyQR,`, 
      "bottom-s2-h1": `the future is here`, 
      "h-s1-p": "Boost efficiency and enhance customer experience with QR menu ordering. Take your restaurant to the next level: Digitalize menus and automate orders.",
      "h-s1-btn": "Try for free",

      "h-s2-ph": "SMART QR ORDERING SYSTEM",
      "h-s2-h1": "TastyQR Ordering & Payment Solutions",
      "h-s2-p": "Enhance your guest experience with TastyQR ordering solutions. Let your customers view your branded digital menu, place their orders and pay the bills to avoid errors and save time. Your staff will have more time for hospitality. TastyQR increases the order amounts with smart upsells and cross-sells.",
      "h-s2-btn": "Sign up now",

      "h-s3-h2": "Learn why Tasty QR is great for your business",
      "h-s3-p-p1": "The",
      "h-s3-p-ps": "TastyQR",
      "h-s3-p-p3": "dashboard offers all features required to manage your ordering environment, from small businesses to enterprises.",
      "h-s3-b1": "Consistent up- and cross-selling",
      "h-s3-b2": "Save on staff costs",
      "h-s3-b3": "Increase your turnover",
      "h-s3-b4": "Integration with POS systems",
      "h-s3-b5": "Implement your own brand identity",
      "h-s3-b6": "Extensive insights and reports",
      "h-s3-b7": "Customize your menu easily",
      "h-s3-btn": "Try for free",

      "h-s4-ph": "No app needed",
      "h-s4-h2": "Experience how easy it is",
      "h-s4-t1-h": "Scan",
      "h-s4-t1-p": "Scan the QR code with their phone and get redirected to the menu with the table number already assigned.",
      "h-s4-t2-h": "Order",
      "h-s4-t2-p": "Choose what you want to eat, add a tip and place the order easily paying online or paying manually after.",
      "h-s4-t3-h": "Done",
      "h-s4-t3-p": "The details of every order are sent automatically to your staff, so they can start preparing it.",

      "h-s5-h2": "Features",
      "h-s5-p": "",
      "features-tab-1": "Menu",
      "features-tab-2": "Dashboard",
      "features-tab-3": "Tablet",
      "h-s5-t1-h": "Online Ordering",
      "h-s5-t1-p": "Boost your restaurant sales as customers enjoy a seamless ordering process, reducing wait times and increasing efficiency.",
      "h-s5-t2-h": "Easy Payments",
      "h-s5-t2-p": "Streamline payments with our online ordering system. Take advantage of Stripe integration and accept payments seamlessly with terminal compatibility.",
      "h-s5-t3-h": "Highly Customizable",
      "h-s5-t3-p": "Take control of your menu with our highly customizable system. Easily update and personalize your offerings to suit your unique brand and culinary vision.",
      "h-s5-t4-h": "Cross & Up Selling",
      "h-s5-t4-p": "Boost your revenue with our system's cross-selling and upselling features, strategically suggesting additional items during the checkout process.",

      "h2-s5-t1-h": "Advanced reporting",
      "h2-s5-t1-p": "Gain valuable insights with our system's advanced reporting, providing comprehensive data and analytics to optimize your business strategies.",
      "h2-s5-t2-h": "Manage your restaurant easily",
      "h2-s5-t2-p": "Effortlessly handle sub-users, QR codes, discount codes, POS connections, payment methods, currency, menu customization & more.",
      "h2-s5-t3-h": "POS Connections",
      "h2-s5-t3-p": "Simplify your operations with our seamless POS connections. Connect our QR system to your POS for automatic order integration and real-time product and stock synchronization.",
      "h2-s5-t4-h": "Manage QR codes",
      "h2-s5-t4-p": "Effortlessly assign QR codes to tables with our convenient feature, allowing users to generate and manage QR codes hassle-free.",

      "h3-s5-t1-h": "Manage Orders",
      "h3-s5-t1-p": "After guests place their order via the QR Menu, the order automatically appears in the tablet with all the necessary information to prepare the order.",
      "h3-s5-t2-h": "Manage QR Codes",
      "h3-s5-t2-p": "The tablet lets you scan new QR codes to assign them to a table or to temporarily deactivate QR codes.",
      "h3-s5-t3-h": "Manage Inventory",
      "h3-s5-t3-p": "Easily manage your inventory with the tablet. Modify prices of certain products, disable certain products (due to out of stock) & more.",

      "h-s6-h2": "Discover the possibilities",
      "h-s6-p": "Elevate your restaurant experience with innovative tools and endless possibilities.",
      "h-s6-t1-h": "Increased tips",
      "h-s6-t1-p": "Encourage customer generosity with an integrated feature that allows customers to add tips (optional) to their orders, boosting your staff earnings.",
      "h-s6-t2-h": "Quick and contactless ordering",
      "h-s6-t2-p": "Enable customers to scan the QR code and place orders directly from their smartphones, reducing wait times.",
      "h-s6-t3-h": "Seamless integration",
      "h-s6-t3-p": "Automate order processing by integrating with the restaurant's point-of-sale (POS) and kitchen systems for efficient workflow management.",
      "h-s6-t4-h": "Multi-language support",
      "h-s6-t4-p": "Cater to a diverse customer base with multi-language support, ensuring that customers can view and order from the menu in their preferred language.",
      "h-s6-t5-h": "Promotions and special offers",
      "h-s6-t5-p": "Showcase limited-time offers, discounts, or loyalty rewards to incentivize repeat business.",
      "h-s6-t6-h": "Analytics and reporting",
      "h-s6-t6-p": "Gain insights into customer ordering patterns, popular dishes, and sales performance to make data-driven decisions.",
      
      "h-s7-h2": "Why operators choose TastyQR",
      "h-s7-p": "Reasons why TastyQR is valuable for your business and why it is worth the small investment. Modernize your restaurant with TastyQR and enjoy of the many advantages it offers.",
      "h-s7-t1-h": "Increase your revenue",
      "h-s7-t1-b1": "Increased Table Turnover",
      "h-s7-t1-b2": "Up & cross selling",
      "h-s7-t1-b3": "Increased Tips",

      "h-s7-t2-h": "Decrease operational costs",
      "h-s7-t2-b1": "Decreased waiting times",
      "h-s7-t2-b2": "Efficient order processing",
      "h-s7-t2-b3": "Less staff required",

      "h-s7-t3-h": "Gain more control",
      "h-s7-t3-b1": "Extensive insights and reports",
      "h-s7-t3-b2": "Easy to manage & edit the menu",
      "h-s7-t3-b3": "Get to know your customers",
      "h-s7-btn": "Get Started",
      "h-s7-btn2": "Try for free",
      "h-s7-link": "Any questions?",

      "h-s8-h2": "what our customers say",
      "h-s8-p": "what our customers say",

      "h-s8-t1-P": "“We denounce with righteous indignation and dislike men who areso beguiled and by the charms of pleasure of the moment.”",
      "h-s8-t1-post": "Reporter",

      "h-s8-t2-P": "“We denounce with righteous indignation and dislike men who areso beguiled and by the charms of pleasure of the moment.”",
      "h-s8-t2-post": "Reporter",

      "h-s8-t3-P": "“We denounce with righteous indignation and dislike men who areso beguiled and by the charms of pleasure of the moment.”",
      "h-s8-t3-post": "Reporter",
      
      "h-s9-h2": "Some numbers that matter",
      "h-s9-p": "There is a reason why we are the best in the market.",

      "h-s9-b1": "Countries",
      "h-s9-b2": "Customer",
      "h-s9-b3": "Orders",
      "h-s9-b4": "Award",

      "h-s10-h2": "Ready to explore the future?",
      "h-s10-p": "Get started for free, no credit card required.",
      "h-s10-btn": "Try for free",

      // Integration Page
      "i-s1-h2": "Seamless integration with your POS",
      "i-s10-p": "Easy integrations with your POS to automate stock and automatically redirect orders from TastyQR to your POS system.",
      
      "i-s2-h2": "POS integrations",
      "i-s3-h2": "Receipt printers",
      
      // DEmo Page

      "d-s1-h2": "Menu demo",
      "d-s1-p": "Experience how your menu could look like. You are able to customize your menu to match the style of your restaurant.",

      "d-t1-h": "1. Customizable Logo",
      "d-t2-h": "2. Your own products & categories",
      "d-t3-h": "3. Allow online orders",
      "d-t4-h": "4. Tip at checkout",

      "d-t1-p": "Upload your logo and customize it with your brand's colors, enhancing your online menu's visual appeal.",
      "d-t2-p": "Set up and manage your products and categories with ease, including customizable options and translations for a tailored experience.",
      "d-t3-p": "Enable seamless online orders through your menu, reducing errors and improving operational efficiency.",
      "d-t4-p": "Incorporate a user-friendly tipping feature at checkout, allowing customers to express their gratitude effortlessly.",

      // faqs
      "f-h2": "Your frequently asked questions",
      "f-p": "If you don’t find answers to your questions here, don’t hesitate to ask. Our customer service team is always ready to help.",


      "f1-h": "How does a POS integration work?",
      "f2-h": "How does TastyQR work without a POS integration?",
      "f3-h": "What are the transaction costs when guests pay online?",
      "f4-h": "Isn't QR ordering considered impersonal?",
      "f5-h": "Do guests always have to pay immediately?",

      "f1-p": "When an integration with your POS is possible, TastyQR actually works as a handheld for your guests. Guests can order themselves, after which the order is automatically added to your POS. With a POS Integration, you can also import & sync products, categories, prices and more to the QR menu.",
      "f2-p": "TastyQR also works well without a POS integration. In this case, the restaurant can choose to send the orders directly to their existing printers. If this is not desired, we can also let the orders come in on a tablet next to the cash register.",
      "f3-p": "iDEAL: €0,29.  MasterCard/VISA (EU): €0,15 + 1,5%.  MasterCard/VISA (Non-EU/Business): €0,15 + 3,7%.  AMEX (EU/Non-EU/Business): €0,25 + 2,6%.  Maestro: €0,40.  Bancontact: €0,20",
      "f4-p": "It's actually not. We see that QR ordering gives guests an extra form of freedom if QR ordering is used in a supportive way. Your guests have several options to order a drink; via the control, or via the online menu for example.",
      "f5-p": "This is not necessary with TastyQR Standard and Professional subscriptions. With these subscriptions you can choose to have the guest pay by with your current terminal afterwards. This means that the order is placed with the QR code and afterwards your waiters give the terminal to your guest to pay.",


      // price page
      "p-s1-p": "Try TastyQR the first 30 days for free. After that, we charge a fixed fee.",

      
      "p-monthly": "Monthly",
      "p-yearly": "Yearly",
      "p-save": "Save",

      "p-month": "month",
      "p-billedAnnually": "Billed Annually",
      "professional": "Custom",
      "professional-start": "Contact us",
      
      
    

      "p-s2-t1-ph": "Starter",
      "p-s2-t2-ph": "Standard",
      "p-s2-t3-ph": "Professional",
      
      "p-s2-t1-p": "Recommended for small businesses with less than 20 tables.",
      "p-s2-t2-p": "Suitable for every type of business, the most chosen subscription.",
      "p-s2-t3-p": "Suitable for any type of business, make the experience even more personal.",

      "p-s2-b1": "Tablet included",
      "p-s2-b2": "Unlimited orders",
      "p-s2-b3": "Online mobile payments",
      "p-s2-b4": "Unlimited tables",
      "p-s2-b5": "Guests can order on tab",
      "p-s2-b7": "POS Integration",
      "p-s2-b8": "Takeaway & delivery platform",
      "p-s2-b9": "Upselling",
      "p-s2-b10": "Cross-Selling",
      "p-s2-b11": "POS integration",
      "p-s2-b12": "Extra locations :",
      "p-s2-b13": "Branding :",
      "p-cl": "Custom Logo",
      "p-color": "Colors",
      "p-fc": "Fully Customized",
      "p-weeks": "Days",
      "p-start": "Start now 30 days for free",
      "p-start2": "Start now",
      "p-start3": "Contact us",
      "compare": "Compare the plans",

      "trial": "trial",
      "plan1-s2-b1": "Tablet included",
      "plan1-s2-b2": "Unlimited orders",
      "plan1-s2-b3": "Unlimited tables",
      "plan1-s2-b4": "Generic QR design",
      "plan1-s2-b5": "POS Integration",
      "plan1-s2-b6": "Upselling & Cross Selling",

      "plan2-s2-b1": "Tablet included",
      "plan2-s2-b2": "Unlimited orders",
      "plan2-s2-b3": "Unlimited tables",
      "plan2-s2-b4": "Custom QR Branding",
      "plan2-s2-b5": "POS Integration",
      "plan2-s2-b6": "Upselling & Cross Selling",

      "plan3-s2-b1": "Tablet Included",
      "plan3-s2-b2": "Unlimited orders",
      "plan3-s2-b3": "Unlimited tables",
      "plan3-s2-b4": "Fully customized branding",
      "plan3-s2-b5": "POS & Printer Integration",
      "plan3-s2-b6": "Upselling & Cross selling",

      "location": "$79 p/m per location",
      "location-p": "Custom",

      // About Page
      'about_title': "About us",
      'about_description': "Who are we? Get to know us and learn about our vission.",
      "a-title": "TastyQR - About Us",
      "a-s3-h1": "Our ExpertsTeam Members",
      "a-s3-p": "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",

      "a-s3-t1-post": "UI/UX Designer",
      "a-s3-t2-post": "UI/UX Designer",
      "a-s3-t3-post": "UI/UX Designer",
      "a-s3-btn": "See All Teams",
      'about-label': "ABOUT US",
      'about-head': "Welcome to TastyQR",
      'about-text': "TastyQR, your gateway to a new era of dining convenience and efficiency. We are passionate about transforming the restaurant experience for both diners and restaurateurs through our innovative QR ordering system. With TastyQR.nl, we're on a mission to simplify restaurant operations and enhance the dining experience, one QR code at a time.",

      'about-label2': "WHY WE STAND OUT",
      'about-head2': "The TastyQR Difference",
      'about-text2': "At TastyQR.nl, we stand out as pioneers in the QR ordering system industry. We've harnessed the power of QR codes to provide a streamlined, user-friendly, and contactless dining solution. With just a scan of a QR code, diners can access restaurant menus, place orders, and make payments right from their own smartphones. This innovative approach not only enhances the dining experience but also improves table turnover and order accuracy for restaurant owners.",

      'about-label3': "HOW WILL THE FUTURE LOOK LIKE",
      'about-head3': "Our Vision",
      'about-text3': "Our vision is to be at the forefront of the dining revolution, making every restaurant visit a memorable experience. We believe that technology should enhance, not replace, the warmth and hospitality of dining out. TastyQR.nl is committed to bringing restaurant-goers and owners closer together in a digital age while preserving the joy of shared meals and the authenticity of in-person service.",
      
      // Contact Page
      "c-s1-h1": "Get in touch with us",
      "c-s1-p": "You can reach us by Whatsapp, Live Chat or Email and we will help you as soon as possible.",

      "c-s2--t1-h": "Whatsapp",
      "c-s2--t2-h": "Email Contact",
      "c-s2--t3-h": "Live chat",

      "c-s2--t1-p": "Expected response time: 3 hours",
      "c-s2--t2-p": "Expected response time: 24 hours",
      "c-s2--t3-p": "Expected response time: 6 hours",

      "c-s2--t1-link": "Chat now",
      "c-s2--t2-link": "Send email",
      "c-s2--t3-link": "Chat now",

      "c-s2-h1": "Contact us",
      "c-s2-b1": "Monday - Friday 9am to 5pm PST",
      "c-s2-b2": "support@Docmate.com",
      "c-s2-b3": "+13-225-456-7890",
      "c-s2-b4": " 1901 Thornridge Cir. Shiloh, Hawaii 81063T",

      "c-s2-l1": "Name *",
      "c-s2-l2": "Company name",
      "c-s2-l3": "Email *",
      "c-s2-l4": "Message *",
      "c-s2-l5": "Message",

      "c-s2-p1": "Please Select Topic",
      "c-s2-p2": "Enter your name here",
      "c-s2-p3": "Enter your company name here",
      "c-s2-p4": "Enter Your email here",
      "c-s2-p5": "Enter your message here",
      "contact": "Send",

    }
  },
  
  es: {
    "translation": {
    "price_header": "Precios claros, sin costos ocultos.",
    "integration_header": "Integración perfecta en tu negocio",
    "Home": "Inicio",
    "Integrations": "Integraciones",
    "Demo": "Demo",
    "Price": "Precios",
    "About": "Sobre nosotros",
    "Contact": "Contacto",
    "Sign-Up": "Prueba Gratuita",
    "h-s1-h1": "TastyQR,",
    "bottom-s2-h1": "el futuro es ahora",
    "h-s1-p": "Ofrece un servicio eficiente mejorando la experiencia del cliente permitiendo pedir mediante QR's. Lleva tu restaurante al siguiente nivel: digitaliza tus menús y automatiza los pedidos.",
    "h-s1-btn": "Prueba Gratuita",
    "h-s2-ph": "CON TASTY QR HACER PEDIDOS NUNCA FUE TAN FÁCIL",
    "h-s2-h1": "¿Que ofrecemos?",
    "h-s2-p": "Mejora la experiencia de tus clientes con las soluciones de pedidos de TastyQR. Permite a tus clientes ver tu menú de forma digital con tu marca y hacer pedidos online para evitar errores y ahorrar tiempo. Tu personal tendrá más tiempo para la hospitalidad. Además, TastyQR aumenta los montos de los pedidos mediante el avanzado sistema de Cross-Selling y Up-Selling.",
    "h-s2-btn": "Regístrate ahora",
    "h-s3-h2": "Descubre por qué TastyQR es excelente para tu negocio",
    "h-s3-p-p1": "",
    "h-s3-p-ps": "TastyQR",
    "h-s3-p-p3": "ofrece todas las funciones necesarias para administrar tu entorno de pedidos, desde pequeñas empresas hasta grandes empresas.",
    "h-s3-b1": "Cross-Selling y Up-Selling",
    "h-s3-b2": "Ahorra en costos de personal",
    "h-s3-b3": "Aumenta tu facturación",
    "h-s3-b4": "Integración con sistemas de punto de venta (POS)",
    "h-s3-b5": "Implementá tu propia identidad de marca",
    "h-s3-b6": "Informes y análisis avanzados",
    "h-s3-b7": "Personalizá fácilmente tu menú",
    "h-s3-btn": "Prueba Gratuita",
    "h-s4-ph": "No se necesita aplicación",
    "h-s4-h2": "Experimenta lo fácil que es",
    "h-s4-t1-h": "Escanear",
    "h-s4-t1-p": "Escanee el código QR con su teléfono y sea redirigido al menú con el número de mesa ya asignado.",
    "h-s4-t2-h": "Hacer pedido",
    "h-s4-t2-p": "Elija lo que desea comer, agregue una propina (opcional) y realice el pedido fácilmente pagando en línea o pagando manualmente después.",
    "h-s4-t3-h": "Hecho",
    "h-s4-t3-p": "Los detalles de cada pedido se envían automáticamente a tu personal para que puedan comenzar a preparar el pedido.",
    "h-s5-h2": "Características",
    "h-s5-p": "",
    "features-tab-1": "Menú",
    "features-tab-2": "Tablero",
    "features-tab-3": "Tableta",
    "h-s5-t1-h": "Pedido en línea",
    "h-s5-t1-p": "Aumenta las ventas de tu restaurante a medida que los clientes disfrutan de un proceso de pedido sin problemas, reduciendo los tiempos de espera y aumentando la eficiencia.",
    "h-s5-t2-h": "Pagos fáciles",
    "h-s5-t2-p": "Simplifica los pagos con nuestro sistema de pedidos en línea. Aprovecha la integración de Stripe y acepta pagos de manera fluida con compatibilidad de terminales.",
    "h-s5-t3-h": "Altamente personalizable",
    "h-s5-t3-p": "Toma el control de tu menú con nuestro sistema altamente personalizable. Actualiza y personaliza fácilmente tus productos para adaptarlos a tu marca única y visión culinaria.",
    "h-s5-t4-h": "Cross-Selling y Up-Selling",
    "h-s5-t4-p": "Aumenta tus ingresos con las funciones de Cross-Selling y Up-Selling de nuestro sistema, sugiriendo estratégicamente artículos adicionales durante el proceso de pago.",
    "h2-s5-t1-h": "Informes avanzados",
    "h2-s5-t1-p": "Obtén información valiosa con los informes avanzados de nuestro sistema, proporcionamos datos y análisis completos para optimizar tus estrategias comerciales.",
    "h2-s5-t2-h": "Gestiona tu restaurante fácilmente",
    "h2-s5-t2-p": "Maneja sin esfuerzo subusuarios, códigos QR, códigos de descuento, conexiones al sistema POS, métodos de pago, monedas de pago, personalización de menú y más.",
    "h2-s5-t3-h": "Conexiones al sistema POS",
    "h2-s5-t3-p": "Simplifica tus operaciones con nuestras conexiones al sistema POS. Conecta nuestro sistema QR a tu sistema POS para la integración automática de pedidos, la sincronización de productos y existencias en tiempo real.",
    "h2-s5-t4-h": "Gestiona los códigos QR",
    "h2-s5-t4-p": "Asigna fácilmente códigos QR a las mesas con nuestra práctica función, que permite a los usuarios generar y administrar códigos QR sin complicaciones.",
    "h3-s5-t1-h": "Gestiona los pedidos",
    "h3-s5-t1-p": "Después de que los clientes hagan su pedido a través del menú QR, el pedido aparecerá automáticamente en la tableta con toda la información necesaria para prepararlo.",
    "h3-s5-t2-h": "Gestiona los códigos QR",
    "h3-s5-t2-p": "La tableta te permite escanear nuevos códigos QR para asignarlos a una mesa o para desactivar temporalmente los códigos QR.",
    "h3-s5-t3-h": "Gestiona el inventario",
    "h3-s5-t3-p": "Administra fácilmente tu inventario con la tableta. Modifica los precios de tus productos, desactiva ciertos productos (debido a la falta de existencias) y más.",
    "h-s6-h2": "Descubre las posibilidades",
    "h-s6-p": "Eleva la experiencia de tu restaurante con herramientas innovadoras y posibilidades infinitas.",
    "h-s6-t1-h": "Aumentá las propinas",
    "h-s6-t1-p": "Aumenta la generosidad de tus clientes con una función integrada que les permite al cliente agregar propinas a sus pedidos y asi aumentar ganancias.",
    "h-s6-t2-h": "Pedido rápido y sin contacto",
    "h-s6-t2-p": "Permite a los clientes escanear el código QR y hacer pedidos directamente desde sus teléfonos, reduciendo los tiempos de espera.",
    "h-s6-t3-h": "Menú digital",
    "h-s6-t3-p": "Los clientes pueden escanear un QR para acceder al menú digital del restaurante. El menú incluye imágenes, descripciones y precios para cada plato.",
    "h-s6-t4-h": "Soporte multilingüe",
    "h-s6-t4-p": "Atiende a una diversa base de clientes con soporte multilingüe, asegurando que los clientes puedan ver y pedir en el menú en su idioma preferido.",
    "h-s6-t5-h": "Promociones y ofertas especiales",
    "h-s6-t5-p": "Muestra ofertas por tiempo limitado, descuentos o recompensas de lealtad para incentivar a tus clientes.",
    "h-s6-t6-h": "Análisis e informes",
    "h-s6-t6-p": "Obtén información sobre los patrones de pedido de los clientes, los platos más populares y el rendimiento de ventas para tomar decisiones basadas en datos.",
    "h-s7-h2": "Por qué los dueños de restaurantes eligen TastyQR",
    "h-s7-p": "Razones por las que TastyQR es valioso para tu negocio y por qué vale la pena la pequeña inversión. Moderniza tu restaurante con TastyQR y disfruta de las muchas ventajas que ofrecemos.",
    "h-s7-t1-h": "Aumenta tus ingresos",
    "h-s7-t1-b1": "Aumenta los ingresos",
    "h-s7-t1-b2": "Cross-Selling y Up-Selling",
    "h-s7-t1-b3": "Aumento de propinas",
    "h-s7-t2-h": "Reduce los costos operativos",
    "h-s7-t2-b1": "Tiempo de espera reducido",
    "h-s7-t2-b2": "Proceso eficiente de pedidos",
    "h-s7-t2-b3": "Menos personal requerido",
    "h-s7-t3-h": "Obtén más control",
    "h-s7-t3-b1": "Informes y análisis exhaustivos",
    "h-s7-t3-b2": "Fácil de gestionar y editar el menú",
    "h-s7-t3-b3": "Conoce a tus clientes",
    "h-s7-btn": "Comenzar",
    "h-s7-btn2": "Prueba Gratuita",
    "h-s7-link": "¿Alguna pregunta?",
    "h-s8-h2": "Lo que dicen nuestros clientes",
    "h-s8-p": "Lo que dicen nuestros clientes",
    "h-s8-t1-P": "“Denunciamos con indignación justa y desaprobamos a los hombres que están tan seducidos y encantados por los placeres del momento.”",
    "h-s8-t1-post": "Reportero",
    "h-s8-t2-P": "“Denunciamos con indignación justa y desaprobamos a los hombres que están tan seducidos y encantados por los placeres del momento.”",
    "h-s8-t2-post": "Reportero",
    "h-s8-t3-P": "“Denunciamos con indignación justa y desaprobamos a los hombres que están tan seducidos y encantados por los placeres del momento.”",
    "h-s8-t3-post": "Reportero",
    "h-s9-h2": "Algunos números que importan",
    "h-s9-p": "Hay una razón por la cual somos los mejores en el mercado.",
    "h-s9-b1": "Países",
    "h-s9-b2": "Clientes",
    "h-s9-b3": "Pedidos",
    "h-s9-b4": "Premio",
    "h-s10-h2": "¿Listo para explorar el futuro?",
    "h-s10-p": "Comienza gratis, sin necesidad de datos bancarios.",
    "h-s10-btn": "Prueba Gratuita",
    "i-s1-h2": "Integración perfecta con tu sistema POS",
    "i-s10-p": "Integraciones fáciles con tu POS para automatizar los pedidos y redirigir automáticamente los pedidos de TastyQR a tu sistema POS.",
    "i-s2-h2": "Integraciones de POS",
    "i-s3-h2": "Impresoras de recibos",
    "d-s1-h2": "Demostración de menú",
    "d-s1-p": "Experimenta cómo podría ser tu menú. Puedes personalizar tu menú para que se adapte al estilo de tu restaurante.",
    "d-t1-h": "1. Logo Personalizable",
    "d-t2-h": "2. Tus Propios Productos y Categorías",
    "d-t3-h": "3. Permitir Pedidos en Línea",
    "d-t4-h": "4. Propina al Pedir",
    "d-t1-p": "Sube tu logo y personalízalo con los colores de tu marca, mejorando el atractivo visual de tu menú en línea.",
    "d-t2-p": "Configura y gestiona tus productos y categorías fácilmente, incluyendo opciones y traducciones personalizables para una experiencia a medida.",
    "d-t3-p": "Habilita pedidos en línea sin complicaciones a través de tu menú, reduciendo errores y mejorando la eficiencia operativa.",
    "d-t4-p": "Incorpora una función de propina fácil de usar al pagar, permitiendo a los clientes expresar su gratitud sin esfuerzo.",
    "f-h2": "Preguntas frecuentes",
    "f-p": "Si no encuentras respuestas a tus preguntas aquí, no dudes en preguntar. Nuestro equipo de atención al cliente está siempre listo para ayudarte.",
    "f1-h": "¿Cómo funciona la integración con el sistema POS?",
    "f2-h": "¿Cómo funciona TastyQR?",
    "f3-h": "¿Cuáles son los costos de transacción cuando los clientes pagan en línea?",
    "f4-h": "¿No se considera impersonal realizar pedidos mediante QR?",
    "f5-h": "¿Como pagan los clientes?",
    "f1-p": "Cuando es posible una integración con tu sistema POS, TastyQR funciona como una herramienta portátil para tus clientes. Los clientes pueden hacer pedidos por sí mismos, después de lo cual el pedido se agrega automáticamente a tu sistema POS. Con una integración con el sistema POS, también puedes importar y sincronizar productos, categorías, precios y más en el menú QR.",
    "f2-p": "Cada codigo QR está asignado a una mesa. Cuando un cliente escanea el QR y hace el pedido, TastyQR envía el pedido a una tablet junto a la caja registradora.",
    "f3-p": "iDEAL: €0,29. MasterCard/VISA (UE): €0,15 + 1,5%. MasterCard/VISA (No-UE/Empresa): €0,15 + 3,7%. AMEX (UE/No-UE/Empresa): €0,25 + 2,6%. Maestro: €0,40. Bancontact: €0,20",
    "f4-p": "En realidad, no lo es. Observamos que el pedido por QR brinda a los clientes una forma adicional de libertad si se utiliza de manera complementaria. Tus clientes tienen varias opciones para pedir una bebida como por ejemplo a través del personal o a través del menú en línea de TastyQR.",
    "f5-p": "Los clientes seguirán pagando como están acostumbrados. Al terminar de comer, piden la cuenta y los mozos cobran como normalmente lo hacen. En este proceso no cambia nada, actualmente se está trabajando para implementar pagos en línea mediante Mercado Pago.",
   // Página de precios
"p-s1-p": "Prueba TastyQR los primeros 30 días de forma gratuita. Después de eso, cobramos una tarifa fija.",

"p-monthly": "Mensual",
"p-yearly": "Anual",
"p-save": "Ahorra",

"p-month": "mes",
"p-billedAnnually": "Facturado anualmente",
"professional": "Personalizado",
"professional-start": "Contáctanos",

"p-s2-t1-ph": "Básico",
"p-s2-t2-ph": "Estándar",
"p-s2-t3-ph": "Profesional",

"p-s2-t1-p": "Recomendado para pequeñas empresas con menos de 20 mesas.",
"p-s2-t2-p": "Adecuado para cualquier tipo de negocio, la suscripción más elegida.",
"p-s2-t3-p": "Adecuado para cualquier tipo de negocio, hace que la experiencia sea aún más personal.",

"p-s2-b1": "Tablet incluida",
"p-s2-b2": "Pedidos ilimitados",
"p-s2-b3": "Pagos móviles en línea",
"p-s2-b4": "Mesas ilimitadas",
"p-s2-b5": "Los invitados pueden pedir en la cuenta",
"p-s2-b7": "Integracion de POS",
"p-s2-b8": "Plataforma para llevar y entrega",
"p-s2-b9": "Upselling",
"p-s2-b10": "Cross selling",
"p-s2-b11": "Integración de POS",
"p-s2-b12": "Ubicaciones adicionales:",
"p-s2-b13": "Marca:",
"p-cl": "Logo",
"p-color": "Colores personalizado",
"p-fc": "Totalmente personalizado",
"p-weeks": "Días",
"p-start": "Comienza ahora 30 días gratis",
"p-start2": "Comienza ahora",
"p-start3": "Contáctanos",
"compare": "Comparar",

"trial": "prueba",
"plan1-s2-b1": "Tablet incluida",
"plan1-s2-b2": "Pedidos ilimitados",
"plan1-s2-b3": "Mesas ilimitadas",
"plan1-s2-b4": "Diseño QR genérico",
"plan1-s2-b5": "Integración de POS",
"plan1-s2-b6": "Up/cross selling",

"plan2-s2-b1": "Tablet incluida",
"plan2-s2-b2": "Pedidos ilimitados",
"plan2-s2-b3": "Mesas ilimitadas",
"plan2-s2-b4": "QR personalizado",
"plan2-s2-b5": "Integración de POS",
"plan2-s2-b6": "Up/cross selling",

"plan3-s2-b1": "Tablet incluida",
"plan3-s2-b2": "Pedidos ilimitados",
"plan3-s2-b3": "Mesas ilimitadas",
"plan3-s2-b4": "QR personalizado",
"plan3-s2-b5": "Integración de POS",
"plan3-s2-b6": "Up/cross selling",

"location": "$79 al mes por ubicación",
"location-p": "Personalizado",

// Página Acerca de
'about_title': "Sobre nosotros",
'about_description': "¿Quiénes somos? Conócenos y descubre nuestra visión.",
"a-title": "TastyQR - Sobre Nosotros",
"a-s3-h1": "Nuestros Miembros del Equipo de Expertos",
"a-s3-p": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",

"a-s3-t1-post": "Diseñador de UI/UX",
"a-s3-t2-post": "Diseñador de UI/UX",
"a-s3-t3-post": "Diseñador de UI/UX",
"a-s3-btn": "Ver todos los equipos",
'about-label': "SOBRE NOSOTROS",
'about-head': "Bienvenido a TastyQR",
'about-text': "TastyQR, tu puerta de entrada a una nueva era de comodidad y eficiencia en el restaurante. Nos apasiona transformar la experiencia de los restaurantes a través de nuestro innovador sistema de pedido mediante códigos QR. En TastyQR, nuestra misión consiste en simplificar las operaciones de restaurantes y mejorar la experiencia del resturante y los clientes.",

'about-label2': "LO QUE NOS DESTACA",
'about-head2': "La Diferencia de TastyQR",
'about-text2': "En TastyQR, nos destacamos como pioneros en la industria de los sistemas de pedidos mediante códigos QR. Hemos aprovechado el poder de los códigos QR para ofrecer una solución de pedidos ágil, fácil de usar y sin contacto. Con solo escanear un código QR, los comensales pueden acceder a los menús de tu restaurante y realizar pedidos directamente desde sus propios celulares. Este enfoque innovador no solo mejora la experiencia a la hora de realizar pedidos, sino que también aumenta los ingresos y la para los dueños de restaurantes y disminuye la posibilidad de error por parte de los comenzales y mozos a la hora de realizar pedidos.",

'about-label3': "CÓMO SERÁ EL FUTURO",
'about-head3': "Nuestra Visión",
'about-text3': "Nuestra visión es estar a la vanguardia de la revolución digital que está sucediendo en los restaurantes, haciendo que cada visita a un restaurante sea una experiencia memorable. Creemos que la tecnología debe mejorar, la calidez y la hospitalidad de comer en restaurantes. TastyQR se compromete a acercar a los visitantes de restaurantes y a los propietarios en la era digital.",

    "about_title": "Acerca de nosotros",
    "about_description": "¿Quiénes somos? Conocé nuestra misión y visión.",
    "a-title": "TastyQR - Acerca de nosotros",
    "a-s3-h1": "Nuestro equipo de expertos",
    "a-s3-p": "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    "a-s3-t1-post": "Diseñador de interfaz de usuario/experiencia de usuario",
    "a-s3-t2-post": "Diseñador de interfaz de usuario/experiencia de usuario",
    "a-s3-t3-post": "Diseñador de interfaz de usuario/experiencia de usuario",
    "a-s3-btn": "Ver todos los equipos",

"c-s1-h1": "Ponte en contacto con nosotros",
"c-s1-p": "Puedes contactarnos por WhatsApp, Livechat o E-Mail y te ayudaremos lo antes posible.",

"c-s2--t1-h": "WhatsApp",
"c-s2--t2-h": "E-Mail",
"c-s2--t3-h": "Livechat",

"c-s2--t1-p": "Tiempo de respuesta: 3 horas",
"c-s2--t2-p": "Tiempo de respuesta: 24 horas",
"c-s2--t3-p": "Tiempo de respuesta: 6 horas",

"c-s2--t1-link": "Chatea ahora",
"c-s2--t2-link": "Enviar e-mail",
"c-s2--t3-link": "Chatea ahora",

"c-s2-h1": "Contáctanos",
"c-s2-b1": "Lunes - Viernes de 9am a 5pm PST",
"c-s2-b2": "support@Docmate.com",
"c-s2-b3": "+13-225-456-7890",
"c-s2-b4": "1901 Thornridge Cir. Shiloh, Hawaii 81063T",

"c-s2-l1": "Nombre *",
"c-s2-l2": "Nombre de la empresa",
"c-s2-l3": "E-mail *",
"c-s2-l4": "Mensaje *",
"c-s2-l5": "Mensaje",

"c-s2-p1": "Selecciona un tema",
"c-s2-p2": "Ingresa tu nombre aquí",
"c-s2-p3": "Ingresa el nombre de tu empresa aquí",
"c-s2-p4": "Ingresa tu e-mail aquí",
"c-s2-p5": "Ingresa tu mensaje aquí",
"contact": "Enviar",

    }
  }

};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;