import React from 'react'
import OrderingBanner from "../assets/images/home-ordering-banner.webp";
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next'


const OrderingSection = () => {
  const { t } = useTranslation();

  return (
    <section className="home-ordering">
    <div className="container-lg">
      <div className="row">
        <div className="col-xl-5 col-md-5">
          <div className="ordering-banner">
            <img src={OrderingBanner} alt="Banner" />
          </div>
        </div>
        <div className="col-xl-7 col-md-7">
          <div className="text">
            <span className="preheader">{t('h-s2-ph')}</span>
            <h2>{t('h-s2-h1')}</h2>
            <p>
            {t('h-s2-p')}
            </p>
            <Link className="btn btn-hollow-blue" to="https://my.tastyqr.nl/auth/signup">
            {t('h-s2-btn')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default OrderingSection