import React, { useState } from 'react'
import Logo from '../assets/images/headerLogo.webp'
import leftArrow from '../assets/images/demo/arrow_left_alt.svg'
import menu from '../assets/images/demo/menu.svg'
import menuitem1 from '../assets/images/demo/menu-item-1.svg'
import cartIcon from '../assets/images/demo/local_mall.svg'
import close from '../assets/images/close.svg'


const MobileAnime = () => {


    const [start, setStart] =useState(true) 
  return (
    <>
        <div className='container-lg'>
            <div className='mobile-container'>

                <div className="row">
                    <div className="container-lg">

                        <iframe title="menu" width="100%" height="650px" src="https://menu.tastyqr.nl/r/rockyburgers-a7dbb26"></iframe>

                    </div>
                </div>
  
               
               { start && <div className= "start-mobile-app">
                        <button className='btn btn-solid-blue' onClick={()=>setStart(!start)}>Start Now</button>
                    </div>
               }
                    
                
               
            </div>
            
            
        </div>
    </>
  )
}

export default MobileAnime