import React from 'react'
import "./Faq.css";

import { useTranslation } from 'react-i18next'


import Accordion from "react-bootstrap/Accordion";

const Faq = () => {
  const { t, i18n } = useTranslation()

  return (
    <section className="faq">
    <div className="container-lg">
      <h2>{t('f-h2')}</h2>
      <p>
      {t('f-p')}
      </p> 
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('f2-h')}</Accordion.Header>
          <Accordion.Body>
          {t('f2-p')}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{t('f4-h')}</Accordion.Header>
          <Accordion.Body>
          {t('f4-p')}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{t('f5-h')}</Accordion.Header>
          <Accordion.Body>
          {t('f5-p')}
          </Accordion.Body>
        </Accordion.Item>
        
      </Accordion>
    </div>
  </section>
  )
}

export default Faq